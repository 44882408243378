/* FONTS */
@font-face {
  font-family: 'akkurat';
  font-weight: normal;
  font-style: normal;
  src : url('../fonts/Akkurat/AKKURAT_.eot');
  src : url('../fonts/Akkurat/AKKURAT_.eot?') format('eot'),
        url('../fonts/Akkurat/AKKURAT_.svg#abcd') format('svg'),
        url('../fonts/Akkurat/AKKURAT_.woff') format('woff'),
        url('../fonts/Akkurat/AKKURAT_.ttf') format('ttf');
}

@font-face {
  font-family: 'akkurat';
  font-weight: bold;
  font-style: normal;
  src : url('../fonts/Akkurat/AKKURATB.eot');
  src : url('../fonts/Akkurat/AKKURATB.eot?') format('eot'),
        url('../fonts/Akkurat/AKKURATB.svg#abcd') format('svg'),
        url('../fonts/Akkurat/AKKURATB.woff') format('woff'),
        url('../fonts/Akkurat/AKKURATB.ttf') format('ttf');
}

/* GENERAL */
a, a:visited, a:hover, a:active {
  color: #000;
  text-decoration: none;
}
a:hover {
  cursor:pointer;
}
body {
  font-family: 'akkurat';
}

/* FIXED */
div.logo {
  position: fixed;
  top: 14px;
  left: 14px;
}
div.languages {
  position: fixed;
  top: 14px;
  right: 14px;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  @media (max-width: 980px) and (-webkit-min-device-pixel-ratio : 1.5), (max-width: 980px) and (min-device-pixel-ratio : 1.5) {
    font-size:1.5em;
    line-height:1em;
  }
  a.active-language {
    font-weight: 600;
  }
}
div.menu{
  position: fixed;
  top: 14px;
  width: 394px;
  left: calc(50% - 197px);
  z-index: 10000;
  @media (max-width: 980px) and (-webkit-min-device-pixel-ratio : 1.5), (max-width: 980px) and (min-device-pixel-ratio : 1.5) {
    font-size: 2em;
    width: 750px;
    left: calc(50% - 375px);
  }
  div.menu_links {
    display: inline-block;
    width: 85px;
    text-align: center;
    margin-left: 14px;
    @media (max-width: 980px) and (-webkit-min-device-pixel-ratio : 1.5), (max-width: 980px) and (min-device-pixel-ratio : 1.5) {
      width: 160px;
    }
    &.active_menu {
      a {
        display: inline-block;
        margin: 0 auto;
      }
    }
  }
  div:first-child {
    text-align: left;
    margin-left: 0;
    &.active_menu {
      a {
        margin-left:0;
      }
    }
  }
  div:last-child {
    text-align: right;
    &.active_menu {
      a {
        margin-right:0;
      }
    }
  }
  div.active_menu {
    font-weight: 600;
  }
  div.active_line {
    height: 2px;
    width: 20px;
    background-color: #000;
    margin-top: 12px;
  }
}