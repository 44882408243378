@import 'common';

.menu {
  display: none;
}
/* CONTENT */
div#projects {
  div.section {
    /* background-color: rgb(197, 199, 198); */
    background-color: rgb(230,230,245);
    div.project_title {
      position: absolute;
      z-index: 4;
      height: 113px;
      width: 100%;
      bottom: 0;
      text-align: center;
      font-size: 22px;
      font-weight:600;
      .centered_container {
        margin-top: 13px;
        text-align: left;
        display: inline-block;
        zoom: 1;
        margin-bottom: 14px;
        .title_underline {
          display: block;
          height:2px;
          width:20px;
          background-color: rgb(0, 0, 255);
          margin-left:1px;
          margin-top: 10px;
        }
      }
      .plus {
        width:14px;
        height:14px;
        background: url(../../images/assets/base_expand_project_details_sprite.png) -5px -5px;
        margin:0 auto;
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        -o-transition-property: -o-transform;
        transition-property: transform;
        &:hover {
          cursor: pointer;
          background: url(../../images/assets/base_expand_project_details_sprite.png) -29px -5px;
        }
      }
    }
    div.project_details_wrap {
      position: absolute;
      top:113px;
      z-index: 5;
      background-color: rgb(197, 199, 198);
      text-align: center;
      div.project_details {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        vertical-align: middle;
        display: inline-block;
        max-width: 780px;
        width: 100%;
        text-align: left;
        div.project_description {
          display: inline-block;
          vertical-align: middle;
          max-width: 388px;
        }
        div.project_field-of-work {
          display: inline-block;
          max-width: 187px;
          float: right;
        }
        div.close_details {
          position: absolute;
          bottom: 115px;
          text-align: center;
          width: 100%;
          font-size:27px;
          a {
            color: rgb(0, 0, 255);
          }
        }
      }
    }
    div.menu_for_projects{
      position: absolute;
      top: 14px;
      width: 394px;
      left: calc(50% - 197px);
      z-index: 10000;
      @media (max-width: 980px) and (-webkit-min-device-pixel-ratio : 1.5), (max-width: 980px) and (min-device-pixel-ratio : 1.5) {
        font-size: 2em;
        width: 750px;
        left: calc(50% - 375px);
      }
      div.menu_links {
        display: inline-block;
        width: 85px;
        text-align: center;
        margin-left: 14px;
        @media (max-width: 980px) and (-webkit-min-device-pixel-ratio : 1.5), (max-width: 980px) and (min-device-pixel-ratio : 1.5) {
          width: 160px;
        }
        &.active_menu {
          a {
            display: inline-block;
            margin: 0 auto;
          }
        }
      }
      div:first-child {
        text-align: left;
        margin-left: 0;
        &.active_menu {
          a {
            margin-left:0;
          }
        }
      }
      div:last-child {
        text-align: right;
        &.active_menu {
          a {
            margin-right:0;
          }
        }
      }
      div.active_menu {
        font-weight: 600;
      }
      div.active_line {
        height: 2px;
        width: 20px;
        background-color: #000;
        margin-top: 12px;
      }
    }
    div.slide {
      position: relative;
      div.top-margin {
        height: 113px;
      }
      div.project_content {
        height: calc(100% - 226px);
        margin: 0 auto;
        position: relative;
        text-align: center;
        .helper_vertical-centered_img {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
        img {
          vertical-align: middle;
          max-height: 100%;
          max-width: calc(100% - 208px);        
        }
      }
    }
  }
  div.section.intro {
    background-color: rgb(255, 252, 240);
    h1.intro_text {
      position: absolute;
      font-size: 48px;
      line-height: 62px;
      letter-spacing: 0px;
      font-weight: 600;
      text-align: left;
      width: 680px;
      top: 26.42%;
      margin-left: 26.39%;
    }
    .scroll-down_arrow {
      position:absolute;
      bottom: 24px;
      left: calc(50% - 7px);
      -webkit-transform:rotate(90deg);
      -moz-transform:rotate(90deg);
      transform: rotate(90deg);
      &:hover {
        cursor: pointer;
      }
    }
  }
}
